<template>
  <div style="width: 100%;">
    <v-container>
      <v-alert
        text color="red lighten-1" outlined prominent type="error"
      >
        <h3 class="text-h5">
          QR inválido
        </h3>
        <div class="pt-4">
          El QR no pudo ser leído correctamente o contiene información inválida.<br>Por favor, vuelva a escanear el QR.</div>
        <v-divider
          class="my-4 red"
        ></v-divider>
        <div>
          Si el problema persiste contáctese con la administración o con <a :href="mailto()">{{ whiteLabel.labels.brand}}</a>
        </div>
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
  }),

  computed: {
    ...mapGetters({
      whiteLabel: "whiteLabel",
    })
  },
  
  methods: {
    mailto() {
      return "mailto:" + this.whiteLabel.domains.email;
    },
  },
};
</script>